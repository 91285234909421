<template>
  <div class="ztbwachali">
    <!-- <div class="bg001">
        <h1><van-icon name="fire-o" color="#ee0a24" />{{jgName}}</h1>
    </div> -->
    <h3>支付方式：微信独立支付</h3> <br>
    {{wstatus1}}
  </div>
  
</template>

<script>
import api from "../../api/ztb";
import { Dialog } from 'vant';

// Dialog({ message: '提示' });
export default {
  // name: "帛钥智能 - 渠道支付",
  components: {
    [Dialog.Component.name]: Dialog.Component,
    
  },
  data() {
    return {
      high: ['rrt','uui'], //高风险区数据
      mid: [], //低风险区域
      dialogVisible: false,
      isShow: false,
      userName: '',
      userPhone: '',
      visible: false,
      jgName: "渠道支付入口检测",
      wstatus1: "",
    };
  },

  // computed: {

  // },

  created() {
    // api.getCovInfo().then((res) => {
    //   console.log(res.data.newslist[0]);
    //   let riskarea = res.data.newslist[0].riskarea;
    //   //解析
    //   // this.high = riskarea.high;
    //   // this.mid = riskarea.mid;
    // }),
    // this.jgNames()
    // this.submitFormUser()
    this.is_weixn()
  },


  methods: {
    jgNames(){
      // http://localhost:8081/#/into1?jg=0002
      if (this.$route.query.jg == '0001'){
        return this.jgName = "职通宝机构"
      }else if(this.$route.query.jg == '0002'){
        return this.jgName = "恒山机构"
      }else if(this.$route.query.jg == '0003'){
        return this.jgName = "华山机构"
      }else if(this.$route.query.jg == '0004'){
        return this.jgName = "嵩山机构"
      }else{
        return this.jgName = "其他机构..."
      }
    },

    //判断是否微信
    is_weixn(){
              var ua = window.navigator.userAgent.toLowerCase();
              if (ua.match(/MicroMessenger/i) == 'micromessenger'){
                console.log('====================微信内支付=====================')
                this.wstatus1 = "状态1-微信内支付模式"
                return true;
              } else {
                console.log('====================微信外支付=====================')
                this.wstatus1 = "状态2-微信外支付模式"
                return false;
              }
    },

    clkZfb(){
      Dialog.alert({
        message: '支付宝渠道暂未开通，请选择微信渠道付款'
      }).then(() => {
        // on close
      })
    },

    clkWach(){
      this.isShow = !this.isShow
    },
    // beforeClose(action, don){
    //   console.log(action)
    //   if(action == confirm){
    //     console.log("用户信息绑定成功！！！")
    //   }
    //   if(action == cancel){
    //     console.log("取消绑定操作 ！！！")
    //   }
    // }

    beforeClose(action, done) {
      if(action === 'confirm') {
          console.log("action = ", action)
          if(!this.userName || !this.userPhone) {
            this.$toast("请输入姓名及电话号码")
            done(false) //不关闭弹框
          }else{
            var data = new URLSearchParams() ;
            data.append('username', this.userName);   // 添加数据
            data.append('userphone', this.userPhone);   // 添加数据this.$route.query.jg
            data.append('institution_code', this.$route.query.jg);
            this.submitFormUser(data)
            setTimeout(done, 1000)
          }

      } else if(action === 'cancel') {
          // console.log("action = ", action)
          // this.$toast("取消绑定！")
          done(true) //关闭
      }
    },

    //发送绑定
    submitFormUser(params){
      api.postWachPay(params).then((res) => {
        console.log("====================h5微信网页支付===============");
        console.log(res);
        // if(res.status == 200){
        //   this.$toast("绑定成功！")
        //   console.log("绑定成功！");
        //   console.log(res.data);
        //   this.sqfk()
        // }else{
        //   console.log("绑定失败！");
        // }
      });
    },

    sqfk(){
      console.log('请求微信授权！！！！')
      setTimeout(
        top.location.href='https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8120e608eacdd78f&redirect_uri=http://235f6cfb.cpolar.cn&response_type=code&scope=snsapi_userinfo&state=type%3Dquan%2Curl%3Dhttp%3A%2F%2Fmm.dianping.com%2Fweixin%2Faccount%2Fhome',
        5000
      )
    },

    // 判断是否中文
    isChinese(temp){
      var re=/[^\u4E00-\u9FA5]/;
      if (re.test(temp)) return false ;
      return true ;
    },

  }
};
</script>

<style lang='less' scoped>
.ztbwachali {
  background: #fff;
}

.bg {
  height: 3.6rem;
  background: url("../../assets/images/area-1.png") no-repeat;
  background-size: cover;
  padding-top: 5rem;
}

.bg001 {
  height: 5rem;
  background: url("../../assets/images/area-1.png") no-repeat;
  background-size: cover;
  padding: 0.25rem 0.25rem;
  color: #D43030;
}
</style>